// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activity-active-users-list-tsx": () => import("./../../../src/pages/Activity/activeUsersList.tsx" /* webpackChunkName: "component---src-pages-activity-active-users-list-tsx" */),
  "component---src-pages-activity-activity-by-user-tsx": () => import("./../../../src/pages/Activity/activityByUser.tsx" /* webpackChunkName: "component---src-pages-activity-activity-by-user-tsx" */),
  "component---src-pages-activity-activity-for-user-tsx": () => import("./../../../src/pages/Activity/activityForUser.tsx" /* webpackChunkName: "component---src-pages-activity-activity-for-user-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-logout-tsx": () => import("./../../../src/pages/auth/logout.tsx" /* webpackChunkName: "component---src-pages-auth-logout-tsx" */),
  "component---src-pages-auth-request-password-tsx": () => import("./../../../src/pages/auth/request-password.tsx" /* webpackChunkName: "component---src-pages-auth-request-password-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-auth-verify-otp-modal-tsx": () => import("./../../../src/pages/auth/verifyOtpModal.tsx" /* webpackChunkName: "component---src-pages-auth-verify-otp-modal-tsx" */),
  "component---src-pages-change-user-index-tsx": () => import("./../../../src/pages/change-user/index.tsx" /* webpackChunkName: "component---src-pages-change-user-index-tsx" */),
  "component---src-pages-charts-chart-tsx": () => import("./../../../src/pages/charts/chart.tsx" /* webpackChunkName: "component---src-pages-charts-chart-tsx" */),
  "component---src-pages-complaints-index-tsx": () => import("./../../../src/pages/complaints/index.tsx" /* webpackChunkName: "component---src-pages-complaints-index-tsx" */),
  "component---src-pages-create-volunteer-index-tsx": () => import("./../../../src/pages/create-volunteer/index.tsx" /* webpackChunkName: "component---src-pages-create-volunteer-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-extra-components-accordion-tsx": () => import("./../../../src/pages/extra-components/accordion.tsx" /* webpackChunkName: "component---src-pages-extra-components-accordion-tsx" */),
  "component---src-pages-extra-components-actiondata-tsx": () => import("./../../../src/pages/extra-components/actiondata.tsx" /* webpackChunkName: "component---src-pages-extra-components-actiondata-tsx" */),
  "component---src-pages-extra-components-actions-tsx": () => import("./../../../src/pages/extra-components/actions.tsx" /* webpackChunkName: "component---src-pages-extra-components-actions-tsx" */),
  "component---src-pages-extra-components-alert-tsx": () => import("./../../../src/pages/extra-components/alert.tsx" /* webpackChunkName: "component---src-pages-extra-components-alert-tsx" */),
  "component---src-pages-extra-components-api-key-tsx": () => import("./../../../src/pages/extra-components/api_key.tsx" /* webpackChunkName: "component---src-pages-extra-components-api-key-tsx" */),
  "component---src-pages-extra-components-cards-tsx": () => import("./../../../src/pages/extra-components/cards.tsx" /* webpackChunkName: "component---src-pages-extra-components-cards-tsx" */),
  "component---src-pages-extra-components-chat-tsx": () => import("./../../../src/pages/extra-components/chat.tsx" /* webpackChunkName: "component---src-pages-extra-components-chat-tsx" */),
  "component---src-pages-extra-components-flip-card-tsx": () => import("./../../../src/pages/extra-components/flip-card.tsx" /* webpackChunkName: "component---src-pages-extra-components-flip-card-tsx" */),
  "component---src-pages-extra-components-index-tsx": () => import("./../../../src/pages/extra-components/index.tsx" /* webpackChunkName: "component---src-pages-extra-components-index-tsx" */),
  "component---src-pages-extra-components-list-tsx": () => import("./../../../src/pages/extra-components/list.tsx" /* webpackChunkName: "component---src-pages-extra-components-list-tsx" */),
  "component---src-pages-extra-components-places-autocomplete-tsx": () => import("./../../../src/pages/extra-components/placesAutocomplete.tsx" /* webpackChunkName: "component---src-pages-extra-components-places-autocomplete-tsx" */),
  "component---src-pages-extra-components-progress-tsx": () => import("./../../../src/pages/extra-components/progress.tsx" /* webpackChunkName: "component---src-pages-extra-components-progress-tsx" */),
  "component---src-pages-extra-components-reveal-card-tsx": () => import("./../../../src/pages/extra-components/reveal-card.tsx" /* webpackChunkName: "component---src-pages-extra-components-reveal-card-tsx" */),
  "component---src-pages-extra-components-spinner-tsx": () => import("./../../../src/pages/extra-components/spinner.tsx" /* webpackChunkName: "component---src-pages-extra-components-spinner-tsx" */),
  "component---src-pages-extra-components-tabs-tsx": () => import("./../../../src/pages/extra-components/tabs.tsx" /* webpackChunkName: "component---src-pages-extra-components-tabs-tsx" */),
  "component---src-pages-family-tree-add-relation-tsx": () => import("./../../../src/pages/family-tree/add-relation.tsx" /* webpackChunkName: "component---src-pages-family-tree-add-relation-tsx" */),
  "component---src-pages-family-tree-add-suggested-relation-tsx": () => import("./../../../src/pages/family-tree/add-suggested-relation.tsx" /* webpackChunkName: "component---src-pages-family-tree-add-suggested-relation-tsx" */),
  "component---src-pages-family-tree-complete-details-of-user-tsx": () => import("./../../../src/pages/family-tree/complete-details-of-user.tsx" /* webpackChunkName: "component---src-pages-family-tree-complete-details-of-user-tsx" */),
  "component---src-pages-family-tree-complete-family-details-tsx": () => import("./../../../src/pages/family-tree/complete-family-details.tsx" /* webpackChunkName: "component---src-pages-family-tree-complete-family-details-tsx" */),
  "component---src-pages-family-tree-delete-marriage-node-tsx": () => import("./../../../src/pages/family-tree/delete-marriage-node.tsx" /* webpackChunkName: "component---src-pages-family-tree-delete-marriage-node-tsx" */),
  "component---src-pages-family-tree-delete-node-tsx": () => import("./../../../src/pages/family-tree/delete-node.tsx" /* webpackChunkName: "component---src-pages-family-tree-delete-node-tsx" */),
  "component---src-pages-family-tree-delete-nodes-component-tsx": () => import("./../../../src/pages/family-tree/delete-nodes-component.tsx" /* webpackChunkName: "component---src-pages-family-tree-delete-nodes-component-tsx" */),
  "component---src-pages-family-tree-delete-parental-node-tsx": () => import("./../../../src/pages/family-tree/delete-parental-node.tsx" /* webpackChunkName: "component---src-pages-family-tree-delete-parental-node-tsx" */),
  "component---src-pages-family-tree-edit-basic-details-tsx": () => import("./../../../src/pages/family-tree/edit-basic-details.tsx" /* webpackChunkName: "component---src-pages-family-tree-edit-basic-details-tsx" */),
  "component---src-pages-family-tree-index-tsx": () => import("./../../../src/pages/family-tree/index.tsx" /* webpackChunkName: "component---src-pages-family-tree-index-tsx" */),
  "component---src-pages-family-tree-mobile-component-tsx": () => import("./../../../src/pages/family-tree/mobile-component.tsx" /* webpackChunkName: "component---src-pages-family-tree-mobile-component-tsx" */),
  "component---src-pages-family-tree-mobile-details-tsx": () => import("./../../../src/pages/family-tree/mobile-details.tsx" /* webpackChunkName: "component---src-pages-family-tree-mobile-details-tsx" */),
  "component---src-pages-family-tree-more-details-tsx": () => import("./../../../src/pages/family-tree/more-details.tsx" /* webpackChunkName: "component---src-pages-family-tree-more-details-tsx" */),
  "component---src-pages-family-tree-node-details-tsx": () => import("./../../../src/pages/family-tree/node-details.tsx" /* webpackChunkName: "component---src-pages-family-tree-node-details-tsx" */),
  "component---src-pages-family-tree-partial-details-of-spouse-tsx": () => import("./../../../src/pages/family-tree/partial-details-of-spouse.tsx" /* webpackChunkName: "component---src-pages-family-tree-partial-details-of-spouse-tsx" */),
  "component---src-pages-family-tree-search-results-tsx": () => import("./../../../src/pages/family-tree/search-results.tsx" /* webpackChunkName: "component---src-pages-family-tree-search-results-tsx" */),
  "component---src-pages-family-tree-select-gautra-tsx": () => import("./../../../src/pages/family-tree/select-gautra.tsx" /* webpackChunkName: "component---src-pages-family-tree-select-gautra-tsx" */),
  "component---src-pages-family-tree-select-name-tsx": () => import("./../../../src/pages/family-tree/select-name.tsx" /* webpackChunkName: "component---src-pages-family-tree-select-name-tsx" */),
  "component---src-pages-family-tree-select-samaj-mitra-tsx": () => import("./../../../src/pages/family-tree/select-samajMitra.tsx" /* webpackChunkName: "component---src-pages-family-tree-select-samaj-mitra-tsx" */),
  "component---src-pages-family-tree-select-village-tsx": () => import("./../../../src/pages/family-tree/select-village.tsx" /* webpackChunkName: "component---src-pages-family-tree-select-village-tsx" */),
  "component---src-pages-family-tree-suggested-nodes-tsx": () => import("./../../../src/pages/family-tree/suggested-nodes.tsx" /* webpackChunkName: "component---src-pages-family-tree-suggested-nodes-tsx" */),
  "component---src-pages-family-tree-toast-message-tsx": () => import("./../../../src/pages/family-tree/toast-message.tsx" /* webpackChunkName: "component---src-pages-family-tree-toast-message-tsx" */),
  "component---src-pages-family-tree-update-family-details-tsx": () => import("./../../../src/pages/family-tree/update-family-details.tsx" /* webpackChunkName: "component---src-pages-family-tree-update-family-details-tsx" */),
  "component---src-pages-family-tree-upload-mukhiya-details-tsx": () => import("./../../../src/pages/family-tree/upload-mukhiya-details.tsx" /* webpackChunkName: "component---src-pages-family-tree-upload-mukhiya-details-tsx" */),
  "component---src-pages-feeds-create-feeds-tsx": () => import("./../../../src/pages/feeds/createFeeds.tsx" /* webpackChunkName: "component---src-pages-feeds-create-feeds-tsx" */),
  "component---src-pages-feeds-post-tsx": () => import("./../../../src/pages/feeds/post.tsx" /* webpackChunkName: "component---src-pages-feeds-post-tsx" */),
  "component---src-pages-feeds-show-all-feeds-tsx": () => import("./../../../src/pages/feeds/showAllFeeds.tsx" /* webpackChunkName: "component---src-pages-feeds-show-all-feeds-tsx" */),
  "component---src-pages-feeds-three-dot-menu-tsx": () => import("./../../../src/pages/feeds/ThreeDotMenu.tsx" /* webpackChunkName: "component---src-pages-feeds-three-dot-menu-tsx" */),
  "component---src-pages-feeds-view-like-comment-share-tsx": () => import("./../../../src/pages/feeds/viewLikeCommentShare.tsx" /* webpackChunkName: "component---src-pages-feeds-view-like-comment-share-tsx" */),
  "component---src-pages-gautra-add-gautra-tsx": () => import("./../../../src/pages/gautra/addGautra.tsx" /* webpackChunkName: "component---src-pages-gautra-add-gautra-tsx" */),
  "component---src-pages-gautra-index-tsx": () => import("./../../../src/pages/gautra/index.tsx" /* webpackChunkName: "component---src-pages-gautra-index-tsx" */),
  "component---src-pages-gautra-manage-gautra-tsx": () => import("./../../../src/pages/gautra/manageGautra.tsx" /* webpackChunkName: "component---src-pages-gautra-manage-gautra-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loader-tsx": () => import("./../../../src/pages/loader.tsx" /* webpackChunkName: "component---src-pages-loader-tsx" */),
  "component---src-pages-map-index-js": () => import("./../../../src/pages/Map/index.js" /* webpackChunkName: "component---src-pages-map-index-js" */),
  "component---src-pages-map-layer-js": () => import("./../../../src/pages/Map/Layer.js" /* webpackChunkName: "component---src-pages-map-layer-js" */),
  "component---src-pages-matrimonials-app-tsx": () => import("./../../../src/pages/matrimonials/App.tsx" /* webpackChunkName: "component---src-pages-matrimonials-app-tsx" */),
  "component---src-pages-matrimonials-component-utils-add-independent-user-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/addIndependentUser.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-add-independent-user-tsx" */),
  "component---src-pages-matrimonials-component-utils-add-member-form-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/addMemberForm.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-add-member-form-tsx" */),
  "component---src-pages-matrimonials-component-utils-create-matrimony-card-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/createMatrimonyCard.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-create-matrimony-card-tsx" */),
  "component---src-pages-matrimonials-component-utils-edit-family-member-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/editFamilyMember.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-edit-family-member-tsx" */),
  "component---src-pages-matrimonials-component-utils-existing-profile-card-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/existingProfileCard.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-existing-profile-card-tsx" */),
  "component---src-pages-matrimonials-component-utils-filter-and-search-profiles-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/filterAndSearchProfiles.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-filter-and-search-profiles-tsx" */),
  "component---src-pages-matrimonials-component-utils-format-display-name-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/formatDisplayName.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-format-display-name-tsx" */),
  "component---src-pages-matrimonials-component-utils-preview-card-header-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/previewCardHeader.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-preview-card-header-tsx" */),
  "component---src-pages-matrimonials-component-utils-search-by-phone-number-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/searchByPhoneNumber.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-search-by-phone-number-tsx" */),
  "component---src-pages-matrimonials-component-utils-search-tsx": () => import("./../../../src/pages/matrimonials/componentUtils/search.tsx" /* webpackChunkName: "component---src-pages-matrimonials-component-utils-search-tsx" */),
  "component---src-pages-matrimonials-context-tsx": () => import("./../../../src/pages/matrimonials/Context.tsx" /* webpackChunkName: "component---src-pages-matrimonials-context-tsx" */),
  "component---src-pages-matrimonials-create-matrimony-tsx": () => import("./../../../src/pages/matrimonials/createMatrimony.tsx" /* webpackChunkName: "component---src-pages-matrimonials-create-matrimony-tsx" */),
  "component---src-pages-matrimonials-edit-profile-tsx": () => import("./../../../src/pages/matrimonials/editProfile.tsx" /* webpackChunkName: "component---src-pages-matrimonials-edit-profile-tsx" */),
  "component---src-pages-matrimonials-found-matches-tsx": () => import("./../../../src/pages/matrimonials/foundMatches.tsx" /* webpackChunkName: "component---src-pages-matrimonials-found-matches-tsx" */),
  "component---src-pages-matrimonials-index-tsx": () => import("./../../../src/pages/matrimonials/index.tsx" /* webpackChunkName: "component---src-pages-matrimonials-index-tsx" */),
  "component---src-pages-matrimonials-initial-values-ts": () => import("./../../../src/pages/matrimonials/initialValues.ts" /* webpackChunkName: "component---src-pages-matrimonials-initial-values-ts" */),
  "component---src-pages-matrimonials-matrimony-profiles-list-tsx": () => import("./../../../src/pages/matrimonials/matrimonyProfilesList.tsx" /* webpackChunkName: "component---src-pages-matrimonials-matrimony-profiles-list-tsx" */),
  "component---src-pages-matrimonials-preview-preview-education-tsx": () => import("./../../../src/pages/matrimonials/preview/previewEducation.tsx" /* webpackChunkName: "component---src-pages-matrimonials-preview-preview-education-tsx" */),
  "component---src-pages-matrimonials-preview-preview-family-member-tsx": () => import("./../../../src/pages/matrimonials/preview/previewFamilyMember.tsx" /* webpackChunkName: "component---src-pages-matrimonials-preview-preview-family-member-tsx" */),
  "component---src-pages-matrimonials-preview-preview-occupation-tsx": () => import("./../../../src/pages/matrimonials/preview/previewOccupation.tsx" /* webpackChunkName: "component---src-pages-matrimonials-preview-preview-occupation-tsx" */),
  "component---src-pages-matrimonials-preview-preview-personal-tsx": () => import("./../../../src/pages/matrimonials/preview/PreviewPersonal.tsx" /* webpackChunkName: "component---src-pages-matrimonials-preview-preview-personal-tsx" */),
  "component---src-pages-matrimonials-preview-preview-photo-kundli-tsx": () => import("./../../../src/pages/matrimonials/preview/previewPhotoKundli.tsx" /* webpackChunkName: "component---src-pages-matrimonials-preview-preview-photo-kundli-tsx" */),
  "component---src-pages-matrimonials-preview-profile-tsx": () => import("./../../../src/pages/matrimonials/previewProfile.tsx" /* webpackChunkName: "component---src-pages-matrimonials-preview-profile-tsx" */),
  "component---src-pages-matrimonials-profiles-list-tsx": () => import("./../../../src/pages/matrimonials/profilesList.tsx" /* webpackChunkName: "component---src-pages-matrimonials-profiles-list-tsx" */),
  "component---src-pages-matrimonials-show-all-profiles-data-tsx": () => import("./../../../src/pages/matrimonials/showAllProfilesData.tsx" /* webpackChunkName: "component---src-pages-matrimonials-show-all-profiles-data-tsx" */),
  "component---src-pages-matrimonials-step-components-first-step-tsx": () => import("./../../../src/pages/matrimonials/stepComponents/FirstStep.tsx" /* webpackChunkName: "component---src-pages-matrimonials-step-components-first-step-tsx" */),
  "component---src-pages-matrimonials-step-components-footer-tsx": () => import("./../../../src/pages/matrimonials/stepComponents/Footer.tsx" /* webpackChunkName: "component---src-pages-matrimonials-step-components-footer-tsx" */),
  "component---src-pages-matrimonials-step-components-fourth-step-tsx": () => import("./../../../src/pages/matrimonials/stepComponents/FourthStep.tsx" /* webpackChunkName: "component---src-pages-matrimonials-step-components-fourth-step-tsx" */),
  "component---src-pages-matrimonials-step-components-second-step-tsx": () => import("./../../../src/pages/matrimonials/stepComponents/SecondStep.tsx" /* webpackChunkName: "component---src-pages-matrimonials-step-components-second-step-tsx" */),
  "component---src-pages-matrimonials-step-components-step-form-tsx": () => import("./../../../src/pages/matrimonials/stepComponents/stepForm.tsx" /* webpackChunkName: "component---src-pages-matrimonials-step-components-step-form-tsx" */),
  "component---src-pages-matrimonials-step-components-third-step-tsx": () => import("./../../../src/pages/matrimonials/stepComponents/ThirdStep.tsx" /* webpackChunkName: "component---src-pages-matrimonials-step-components-third-step-tsx" */),
  "component---src-pages-matrimonials-style-utils-index-tsx": () => import("./../../../src/pages/matrimonials/styleUtils/index.tsx" /* webpackChunkName: "component---src-pages-matrimonials-style-utils-index-tsx" */),
  "component---src-pages-miscellaneous-actions-index-tsx": () => import("./../../../src/pages/miscellaneous-actions/index.tsx" /* webpackChunkName: "component---src-pages-miscellaneous-actions-index-tsx" */),
  "component---src-pages-networkgraph-index-js": () => import("./../../../src/pages/networkgraph/index.js" /* webpackChunkName: "component---src-pages-networkgraph-index-js" */),
  "component---src-pages-networkgraph-visjs-tree-js": () => import("./../../../src/pages/networkgraph/visjsTree.js" /* webpackChunkName: "component---src-pages-networkgraph-visjs-tree-js" */),
  "component---src-pages-photos-upload-photos-tsx": () => import("./../../../src/pages/photos/uploadPhotos.tsx" /* webpackChunkName: "component---src-pages-photos-upload-photos-tsx" */),
  "component---src-pages-photos-view-photos-tsx": () => import("./../../../src/pages/photos/viewPhotos.tsx" /* webpackChunkName: "component---src-pages-photos-view-photos-tsx" */),
  "component---src-pages-projects-create-project-tsx": () => import("./../../../src/pages/projects/createProject.tsx" /* webpackChunkName: "component---src-pages-projects-create-project-tsx" */),
  "component---src-pages-projects-donation-modal-tsx": () => import("./../../../src/pages/projects/DonationModal.tsx" /* webpackChunkName: "component---src-pages-projects-donation-modal-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-project-card-tsx": () => import("./../../../src/pages/projects/projectCard.tsx" /* webpackChunkName: "component---src-pages-projects-project-card-tsx" */),
  "component---src-pages-projects-project-details-tsx": () => import("./../../../src/pages/projects/projectDetails.tsx" /* webpackChunkName: "component---src-pages-projects-project-details-tsx" */),
  "component---src-pages-registrations-index-tsx": () => import("./../../../src/pages/registrations/index.tsx" /* webpackChunkName: "component---src-pages-registrations-index-tsx" */),
  "component---src-pages-samaj-mitra-index-tsx": () => import("./../../../src/pages/samaj_mitra/index.tsx" /* webpackChunkName: "component---src-pages-samaj-mitra-index-tsx" */),
  "component---src-pages-samaj-mitra-mukhiya-form-tsx": () => import("./../../../src/pages/samaj_mitra/mukhiyaForm.tsx" /* webpackChunkName: "component---src-pages-samaj-mitra-mukhiya-form-tsx" */),
  "component---src-pages-samaj-mitra-mukhiyas-list-tsx": () => import("./../../../src/pages/samaj_mitra/mukhiyasList.tsx" /* webpackChunkName: "component---src-pages-samaj-mitra-mukhiyas-list-tsx" */),
  "component---src-pages-samaj-mitra-mukhiyas-tree-list-view-tsx": () => import("./../../../src/pages/samaj_mitra/mukhiyasTreeListView.tsx" /* webpackChunkName: "component---src-pages-samaj-mitra-mukhiyas-tree-list-view-tsx" */),
  "component---src-pages-samaj-mitra-node-component-tsx": () => import("./../../../src/pages/samaj_mitra/NodeComponent.tsx" /* webpackChunkName: "component---src-pages-samaj-mitra-node-component-tsx" */),
  "component---src-pages-samaj-mitra-phone-number-data-js": () => import("./../../../src/pages/samaj_mitra/phoneNumberData.js" /* webpackChunkName: "component---src-pages-samaj-mitra-phone-number-data-js" */),
  "component---src-pages-search-search-form-tsx": () => import("./../../../src/pages/search/searchForm.tsx" /* webpackChunkName: "component---src-pages-search-search-form-tsx" */),
  "component---src-pages-search-search-modal-tsx": () => import("./../../../src/pages/search/searchModal.tsx" /* webpackChunkName: "component---src-pages-search-search-modal-tsx" */),
  "component---src-pages-search-search-result-table-tsx": () => import("./../../../src/pages/search/search_result_table.tsx" /* webpackChunkName: "component---src-pages-search-search-result-table-tsx" */),
  "component---src-pages-update-names-index-tsx": () => import("./../../../src/pages/update-names/index.tsx" /* webpackChunkName: "component---src-pages-update-names-index-tsx" */),
  "component---src-pages-users-edit-user-tsx": () => import("./../../../src/pages/users/editUser.tsx" /* webpackChunkName: "component---src-pages-users-edit-user-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */),
  "component---src-pages-villages-filter-villages-tsx": () => import("./../../../src/pages/villages/filterVillages.tsx" /* webpackChunkName: "component---src-pages-villages-filter-villages-tsx" */),
  "component---src-pages-villages-index-tsx": () => import("./../../../src/pages/villages/index.tsx" /* webpackChunkName: "component---src-pages-villages-index-tsx" */),
  "component---src-pages-villages-select-options-tsx": () => import("./../../../src/pages/villages/select-options.tsx" /* webpackChunkName: "component---src-pages-villages-select-options-tsx" */),
  "component---src-pages-villages-user-given-village-details-tsx": () => import("./../../../src/pages/villages/UserGivenVillageDetails.tsx" /* webpackChunkName: "component---src-pages-villages-user-given-village-details-tsx" */),
  "component---src-pages-villages-user-given-villlage-details-edit-modal-tsx": () => import("./../../../src/pages/villages/UserGivenVilllageDetailsEditModal.tsx" /* webpackChunkName: "component---src-pages-villages-user-given-villlage-details-edit-modal-tsx" */),
  "component---src-pages-villages-users-of-village-tsx": () => import("./../../../src/pages/villages/usersOfVillage.tsx" /* webpackChunkName: "component---src-pages-villages-users-of-village-tsx" */),
  "component---src-pages-villages-verify-tsx": () => import("./../../../src/pages/villages/verify.tsx" /* webpackChunkName: "component---src-pages-villages-verify-tsx" */),
  "component---src-pages-villages-village-details-tsx": () => import("./../../../src/pages/villages/VillageDetails.tsx" /* webpackChunkName: "component---src-pages-villages-village-details-tsx" */),
  "component---src-pages-villages-village-google-map-info-tsx": () => import("./../../../src/pages/villages/VillageGoogleMapInfo.tsx" /* webpackChunkName: "component---src-pages-villages-village-google-map-info-tsx" */),
  "component---src-pages-wedding-qr-groom-bride-heading-tsx": () => import("./../../../src/pages/weddingQR/GroomBrideHeading.tsx" /* webpackChunkName: "component---src-pages-wedding-qr-groom-bride-heading-tsx" */),
  "component---src-pages-wedding-qr-index-tsx": () => import("./../../../src/pages/weddingQR/index.tsx" /* webpackChunkName: "component---src-pages-wedding-qr-index-tsx" */),
  "component---src-pages-wedding-qr-login-tsx": () => import("./../../../src/pages/weddingQR/login.tsx" /* webpackChunkName: "component---src-pages-wedding-qr-login-tsx" */),
  "component---src-pages-wedding-qr-side-tsx": () => import("./../../../src/pages/weddingQR/Side.tsx" /* webpackChunkName: "component---src-pages-wedding-qr-side-tsx" */),
  "component---src-pages-wedding-qr-verify-otp-modal-tsx": () => import("./../../../src/pages/weddingQR/VerifyOtpModal.tsx" /* webpackChunkName: "component---src-pages-wedding-qr-verify-otp-modal-tsx" */),
  "component---src-pages-weddings-add-new-wedding-tsx": () => import("./../../../src/pages/weddings/addNewWedding.tsx" /* webpackChunkName: "component---src-pages-weddings-add-new-wedding-tsx" */),
  "component---src-pages-weddings-all-wedding-detail-tsx": () => import("./../../../src/pages/weddings/allWeddingDetail.tsx" /* webpackChunkName: "component---src-pages-weddings-all-wedding-detail-tsx" */),
  "component---src-pages-weddings-create-sub-events-tsx": () => import("./../../../src/pages/weddings/createSubEvents.tsx" /* webpackChunkName: "component---src-pages-weddings-create-sub-events-tsx" */),
  "component---src-pages-weddings-edit-sub-event-modal-tsx": () => import("./../../../src/pages/weddings/editSubEventModal.tsx" /* webpackChunkName: "component---src-pages-weddings-edit-sub-event-modal-tsx" */),
  "component---src-pages-weddings-manage-wedding-tsx": () => import("./../../../src/pages/weddings/manageWedding.tsx" /* webpackChunkName: "component---src-pages-weddings-manage-wedding-tsx" */),
  "component---src-pages-weddings-move-photo-modal-tsx": () => import("./../../../src/pages/weddings/movePhotoModal.tsx" /* webpackChunkName: "component---src-pages-weddings-move-photo-modal-tsx" */),
  "component---src-pages-weddings-upload-wedding-card-tsx": () => import("./../../../src/pages/weddings/uploadWeddingCard.tsx" /* webpackChunkName: "component---src-pages-weddings-upload-wedding-card-tsx" */),
  "component---src-pages-weddings-view-sub-events-tsx": () => import("./../../../src/pages/weddings/viewSubEvents.tsx" /* webpackChunkName: "component---src-pages-weddings-view-sub-events-tsx" */),
  "component---src-pages-weddings-wedding-box-tsx": () => import("./../../../src/pages/weddings/weddingBox.tsx" /* webpackChunkName: "component---src-pages-weddings-wedding-box-tsx" */),
  "component---src-pages-weddings-wedding-detail-tsx": () => import("./../../../src/pages/weddings/weddingDetail.tsx" /* webpackChunkName: "component---src-pages-weddings-wedding-detail-tsx" */),
  "component---src-pages-whatsapp-modal-form-tsx": () => import("./../../../src/pages/whatsapp/modalForm.tsx" /* webpackChunkName: "component---src-pages-whatsapp-modal-form-tsx" */),
  "component---src-pages-whatsapp-templates-tsx": () => import("./../../../src/pages/whatsapp/templates.tsx" /* webpackChunkName: "component---src-pages-whatsapp-templates-tsx" */)
}

