import { MenuItemType } from '@paljs/ui/types';

const items: MenuItemType[] = [
  {
    title: 'Home Page',
    icon: { name: 'home' },
    link: { to: '/dashboard' },
  },
  {
    title: 'FEATURES',
    group: true,
  },
  // {
  //   title: 'Extra Components',
  //   icon: { name: 'star-outline' },
  //   children: [
  //     {
  //       title: 'Accordion',
  //       link: { to: '/extra-components/accordion' },
  //     },
  //     {
  //       title: 'Actions',
  //       link: { to: '/extra-components/actions' },
  //     },
  //     {
  //       title: 'Alert',
  //       link: { to: '/extra-components/alert' },
  //     },
  //     {
  //       title: 'List',
  //       link: { to: '/extra-components/list' },
  //     },
  //     {
  //       title: 'Spinner',
  //       link: { to: '/extra-components/spinner' },
  //     },
  //     {
  //       title: 'Progress Bar',
  //       link: { to: '/extra-components/progress' },
  //     },
  //     {
  //       title: 'Tabs',
  //       link: { to: '/extra-components/tabs' },
  //     },
  //     {
  //       title: 'Chat',
  //       link: { to: '/extra-components/chat' },
  //     },
  //     {
  //       title: 'Cards',
  //       link: { to: '/extra-components/cards' },
  //     },
  //     {
  //       title: 'Flip Card',
  //       link: { to: '/extra-components/flip-card' },
  //     },
  //     {
  //       title: 'Reveal Card',
  //       link: { to: '/extra-components/reveal-card' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Forms',
  //   icon: { name: 'edit-2-outline' },
  //   children: [
  //     {
  //       title: 'Inputs',
  //       link: { to: '/forms/inputs' },
  //     },
  //     {
  //       title: 'Layout',
  //       link: { to: '/forms/form-layout' },
  //     },
  //     {
  //       title: 'Buttons',
  //       link: { to: '/forms/buttons' },
  //     },
  //     {
  //       title: 'Select',
  //       link: { to: '/forms/select' },
  //     },
  //   ],
  // },
  // {
  //   title: 'UI Features',
  //   icon: { name: 'keypad-outline' },
  //   children: [
  //     {
  //       title: 'Grid',
  //       link: { to: '/ui-features/grid' },
  //     },
  //     {
  //       title: 'Animated Searches',
  //       link: { to: '/ui-features/search' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Modal & Overlays',
  //   icon: { name: 'browser-outline' },
  //   children: [
  //     {
  //       title: 'Popover',
  //       link: { to: '/modal-overlays/popover' },
  //     },
  //     {
  //       title: 'Tooltip',
  //       link: { to: '/modal-overlays/tooltip' },
  //     },
  //     {
  //       title: 'Toastr',
  //       link: { to: '/modal-overlays/toastr' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Editors',
  //   icon: { name: 'text-outline' },
  //   children: [
  //     {
  //       title: 'TinyMCE',
  //       link: { to: '/editors/tinymce' },
  //     },
  //     {
  //       title: 'CKEditor',
  //       link: { to: '/editors/ckeditor' },
  //     },
  //   ],
  // },
  // {
  //   title: 'Miscellaneous',
  //   icon: { name: 'shuffle-2-outline' },
  //   children: [
  //     {
  //       title: '404',
  //       link: { to: '/miscellaneous/404' },
  //     },
  //   ],
  // },
  {
    title: 'Create Volunteer',
    icon: { name: 'people-outline' },
    link: { to: '/create-volunteer' },
  },
  {
    title: 'Projects',
    icon: { name: 'people-outline' },
    link: { to: '/projects' },
  },
  {
    title: 'वंशावली',
    icon: { name: 'people-outline' }, // family-tree
    link: { to: '/networkgraph'},

  },
  {
    title: 'Samaj Mitra',
    icon: { name: 'people-outline' },
    link: { to: '/samaj_mitra' },
  },
  {
    title: 'Activity',
    icon: { name: 'lock-outline' },
    children: [
      {
        title: 'Active users',
        link: { to: '/Activity/activeUsersList' },
      },
    ],
  },
  {
    title: 'Gautra',
    icon: { name: 'lock-outline' },
    children: [
      // {
      //   title: 'Manage Gautra',
      //   link: { to: '/gautra' },
      // },
      {
        title: 'Add New Gautra',
        link: { to: '/gautra/addGautra' },
      },
      {
        title: 'Manage Gautra',
        link: { to: '/gautra/manageGautra' },
      },
    ],
  },
  {
    title: 'Growth',
    icon: { name: 'trending-up-outline' },
    link: { to: '/charts/chart' },
  },
  {
    title: 'Complaints',
    icon: { name: 'star-outline' },
    link: { to: '/complaints' },
  },
  {
    title: 'Users',
    icon: { name: 'person-outline' },
    link: { to: '/users' },
  },
  {
    title: 'Update Names',
    icon: { name: 'person-outline' },
    link: { to: '/update-names' },
  },
  {
    title: 'Matrimony',
    icon: { name: 'people-outline' },
    children: [
      {
        title: 'Create Matrimony Profiles',
        link: { to: '/matrimonials/createMatrimony' },
      },
      {
        title: 'Matrimony Profiles',
        link: { to: '/matrimonials/matrimonyProfilesList' },
      },

      {
        title: 'Show All Matrimony Profiles',
        link: { to: '/matrimonials/showAllProfilesData' },
      },
    ],
  },

  {
    title: 'Weddings',
    icon: { name: 'lock-outline' },
    children: [
      {
        title: 'Manage Weddings',
        link: { to: '/weddings/manageWedding' },
      },

      {
        title: 'Create New Wedding',
        link: { to: '/weddings/addNewWedding' },
      },
    ],
  },

  {
    title: 'Feed',
    icon: { name: 'inbox-outline' },
    children: [
      {
        title: 'Create Post',
        link: { to: '/feeds/createFeeds' },
      },

      {
        title: 'See All Posts',
        link: { to: '/feeds/showAllFeeds' },
      },
    ],
  },
  {
    title: 'Map',
    icon: { name: 'person-outline' },
    link: { to: '/Map' },
  },
  {
    title: 'Villages',
    icon: { name: 'paper-plane-outline' },
    link: { to: '/villages' },
  },
  {
    title: 'Miscellaneous Actions',
    icon: { name: 'paper-plane-outline' },
    link: { to: '/miscellaneous-actions' },
  },
  {
    title: 'Registrations',
    icon: { name: 'people-outline' },
    link: { to: '/registrations' },
  },

  {
    title: 'Family Tree',
    icon: { name: 'options-outline' },
    link: { to: '/family-tree' },
  },

  // {
  //   title: 'Auth',
  //   icon: { name: 'lock-outline' },
  //   children: [
  //     {
  //       title: 'Login',
  //       link: { to: '/auth/login' },
  //     },
  //     {
  //       title: 'Register',
  //       link: { to: '/auth/register' },
  //     },
  //     {
  //       title: 'Request Password',
  //       link: { to: '/auth/request-password' },
  //     },
  //     {
  //       title: 'Reset Password',
  //       link: { to: '/auth/reset-password' },
  //     },
  //   ],
  // },
];

export default items;
